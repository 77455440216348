// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".security_styles_tabs__fVViA {\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n  justify-content: flex-start;\n  border-bottom: 1px solid #d8d8d8;\n  margin-bottom: 8px;\n}\n\n.security_styles_tab__4uv7o {\n  padding: 8px 16px;\n  border-radius: 4px 4px 0 0;\n  margin-right: 8px;\n  color: black;\n  text-decoration: none;\n  transition: 0.2s;\n}\n\n.security_styles_tab__4uv7o:hover {\n  cursor: pointer;\n}\n\n.security_styles_activeTab__E-iuJ {\n  background-color: rgba(0 0 0 / 10%);\n  pointer-events: none;\n}\n", "",{"version":3,"sources":["webpack://./src/containers/Tabs/styles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,mBAAmB;EACnB,2BAA2B;EAC3B,gCAAgC;EAChC,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,0BAA0B;EAC1B,iBAAiB;EACjB,YAAY;EACZ,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,mCAAmC;EACnC,oBAAoB;AACtB","sourcesContent":[".tabs {\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n  justify-content: flex-start;\n  border-bottom: 1px solid #d8d8d8;\n  margin-bottom: 8px;\n}\n\n.tab {\n  padding: 8px 16px;\n  border-radius: 4px 4px 0 0;\n  margin-right: 8px;\n  color: black;\n  text-decoration: none;\n  transition: 0.2s;\n}\n\n.tab:hover {\n  cursor: pointer;\n}\n\n.activeTab {\n  background-color: rgba(0 0 0 / 10%);\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs": "security_styles_tabs__fVViA",
	"tab": "security_styles_tab__4uv7o",
	"activeTab": "security_styles_activeTab__E-iuJ"
};
export default ___CSS_LOADER_EXPORT___;
