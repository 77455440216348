import React from "react";
import cn from "classnames";
import SecureScoreHistoryChart from "./charts/SecureScoreHistoryChart";
// import SecureScoreCategorisedChart from "./charts/SecureScoreCategorisedChart";
import IntuneEnrolledDevicesChart from "./charts/IntuneEnrolledDevicesChart";
import RiskySigninsTable from "./tables/RiskySigninsTable";
import RiskyUsersTable from "./tables/RiskyUsersTable";

import styles from "./styles.module.css";

const StartPageCharts = () => {
  return (
    <div className={styles.chartsContainer}>
      <div className={cn(styles.chartsItem, styles.bigCard)}>
        <SecureScoreHistoryChart />
      </div>
      {/* <div className={cn(styles.chartsItem, styles.smallCard)}>
        <SecureScoreCategorisedChart />
      </div> */}
      <div className={cn(styles.chartsItem, styles.smallCard)}>
        <IntuneEnrolledDevicesChart />
      </div>
      <div className={cn(styles.chartsItem)} id="securityNews"></div>
      <div className={cn(styles.chartsItem, styles.mediumCard)}>
        <RiskySigninsTable />
      </div>
      <div className={cn(styles.chartsItem, styles.mediumCard)}>
        <RiskyUsersTable />
      </div>
    </div>
  );
};

export default StartPageCharts;
