import React from "react";
import ContractDropdown from "containers/ContractDropdown";
import { useTranslation } from "react-i18next";
import { Button, Title } from "@skyportal/ui-kit";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { contractSelectors } from "store/contracts";

import styles from "./styles.module.css";
import ModuleBreadcrumbs from "containers/ModuleBreadcrumbs";

export interface PageHeaderProps {
  title: React.ReactNode;
  withNavigationLinks?: boolean;
}

const PageHeader: React.FC<PageHeaderProps> = ({ title, withNavigationLinks = false }) => {
  const { t } = useTranslation("common");
  const { SE: SEContracts, SD: SDContracts } = useSelector(contractSelectors.getContractsSlice);

  return (
    <header className={styles.root}>
      <div className={styles.titleBlock}>
        <ModuleBreadcrumbs />
        <Title level={2}>{title}</Title>
      </div>
      <div className={styles.subtitleBlock}>
        <div className={styles.navigationBlock}>
          <ContractDropdown className={styles.contractDropdown} />
          {withNavigationLinks && (
            <div className={styles.linksBlock}>
              <Link to={"/security/proactive-security"}>
                <Button type="secondary" rightIcon="arrow-right" disabled={!SEContracts.length}>
                  {t("proactiveSecurityPage:title")}
                </Button>
              </Link>
              <Link to={"/security/detection-and-response"}>
                <Button type="secondary" rightIcon="arrow-right" disabled={!SDContracts.length}>
                  {t("detectAndResponsePage:title")}
                </Button>
              </Link>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default PageHeader;
