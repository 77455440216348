import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { Button, Card, Icon, Spinner, Table, Tooltip } from "@skyportal/ui-kit";
import { useTranslation } from "react-i18next";
import { RequestStatus } from "types/common.types";
import { proactiveSecurityPageSelectors, proactiveSecurityPageThunks } from "store/proactiveSecurityPage";
import { RecommendationsModel } from "types/proactiveSecurityApi.types";
import { useThunkDispatch } from "store";
import { contractSelectors } from "store/contracts";
import CardFallback from "containers/CardFallback";

import styles from "../styles.module.css";

const RecommendationsTable = () => {
  const { t } = useTranslation("proactiveSecurityPage");
  const { list, requestStatus } = useSelector(proactiveSecurityPageSelectors.getRecommendations);

  const thunkDispatch = useThunkDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContract)?.name as string;
  const reloadData = useCallback(
    () => thunkDispatch(proactiveSecurityPageThunks.getRecommendationsInfo(selectedContractId)),
    [selectedContractId, thunkDispatch]
  );

  const columnsConfig = useMemo(
    () => [
      {
        key: "recommendation",
        dataIndex: "recommendation",
        title: t("recommendationsTableColumns.recommendation"),
        sortDisabled: true,
        width: "55%",
      },
      {
        key: "exposedDevices",
        dataIndex: "exposedDevices",
        title: t("recommendationsTableColumns.exposedDevices"),
        sortDisabled: true,
        width: "15%",
      },
      {
        key: "threats",
        dataIndex: "threats",
        title: t("recommendationsTableColumns.threats"),
        sortDisabled: true,
        width: "15%",
        render: (_: undefined, item: RecommendationsModel) => {
          return (
            <>
              <Tooltip
                title={item.activeAlert ? t("recommendationsActiveAlertText") : t("recommendationsInactiveAlertText")}
                classes={{ tooltip: styles.tooltip }}
              >
                <Button type="tertiary" size="small" className={styles.threatButton}>
                  <Icon
                    type="fontawesome"
                    icon="crosshairs"
                    className={styles.threatIcon}
                    color={item.activeAlert ? "#000000" : "#d8d8d8"}
                  />
                </Button>
              </Tooltip>
              <Tooltip
                title={
                  item.publicExploit ? t("recommendationsPublicExploitText") : t("recommendationsNoPublicExploitText")
                }
                classes={{ tooltip: styles.tooltip }}
              >
                <Button type="tertiary" size="small" className={styles.threatButton}>
                  <Icon
                    type="fontawesome"
                    icon="bug"
                    className={styles.threatIcon}
                    color={item.publicExploit ? "#e32910" : "#d8d8d8"}
                  />
                </Button>
              </Tooltip>
            </>
          );
        },
      },
      {
        key: "impact",
        dataIndex: "impact",
        title: t("recommendationsTableColumns.impact"),
        sortDisabled: true,
        width: "15%",
      },
    ],
    [t]
  );

  return (
    <Card title={t("recommendationsTableTitle")} className={styles.card} data-testid="recommendationsTable">
      <div className={styles.cardContent}>
        {requestStatus === RequestStatus.PENDING && <Spinner show />}
        {requestStatus === RequestStatus.SUCCESS && (
          <div className={styles.tableBlock}>
            <Table dataSource={list} rowKey="recommendation" columns={columnsConfig} />
          </div>
        )}
        {requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadData} />}
      </div>
    </Card>
  );
};

export default RecommendationsTable;
