import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { Card, Spinner, Table } from "@skyportal/ui-kit";
import { useTranslation } from "react-i18next";
import { RequestStatus } from "types/common.types";
import { startPageSelectors, startPageThunks } from "store/startPage";
import { useThunkDispatch } from "store";
import { contractSelectors } from "store/contracts";
import CardFallback from "containers/CardFallback";
import { RiskyUserModel } from "types/startPageApi.types";
import { formatDate } from "utils/time";

import styles from "../styles.module.css";

const RiskyUsersTable = () => {
  const { t } = useTranslation("securityPage");
  const { list, requestStatus } = useSelector(startPageSelectors.getRiskyUsers);

  const thunkDispatch = useThunkDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContract)?.name as string;
  const reloadData = useCallback(
    () => thunkDispatch(startPageThunks.getRiskyUsersInfo(selectedContractId)),
    [selectedContractId, thunkDispatch]
  );

  const columnsConfig = useMemo(
    () => [
      {
        key: "user",
        dataIndex: "user",
        title: t("riskyUsersTableColumns.user"),
        sortDisabled: true,
      },
      {
        key: "riskLevel",
        dataIndex: "riskLevel",
        title: t("riskyUsersTableColumns.riskLevel"),
        sortDisabled: true,
      },
      {
        key: "lastUpdated",
        dataIndex: "lastUpdated",
        title: t("riskyUsersTableColumns.lastUpdated"),
        sortDisabled: true,
        render: (lastUpdated: RiskyUserModel["lastUpdated"]) => formatDate(lastUpdated),
      },
    ],
    [t]
  );

  return (
    <Card title={t("riskyUsersCardTitle")} className={styles.card} data-testid="riskyUsersTable">
      <div className={styles.riskyTableCardContent}>
        {requestStatus === RequestStatus.PENDING && <Spinner show />}
        {requestStatus === RequestStatus.SUCCESS &&
          (list.length ? (
            <div className={styles.tableBlock}>
              <Table dataSource={list} rowKey="riskUser" columns={columnsConfig} />
            </div>
          ) : (
            <div className={styles.noDataText}>{t("noRiskyUsersText")}</div>
          ))}
        {requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadData} />}
      </div>
    </Card>
  );
};

export default RiskyUsersTable;
