import React from "react";
import cn from "classnames";
import UnresolvedIncidentsChart from "./charts/UnresolvedIncidentsChart";
import IncidentSeverityChart from "./charts/IncidentSeverityChart";
import IncidentStatusChart from "./charts/IncidentStatusChart";
import LatestIncidentsTable from "./tables/LatestIncidentsTable";

import styles from "./styles.module.css";

const DetectAndResponseOverview = () => {
  return (
    <div className={styles.chartsContainer}>
      <div className={cn(styles.chartsItem, styles.smallCard)}>
        <UnresolvedIncidentsChart />
      </div>
      <div className={cn(styles.chartsItem, styles.smallCard)}>
        <IncidentSeverityChart />
      </div>
      <div className={cn(styles.chartsItem, styles.smallCard)}>
        <IncidentStatusChart />
      </div>
      <div className={cn(styles.chartsItem, styles.bigCard)}>
        <LatestIncidentsTable />
      </div>
    </div>
  );
};

export default DetectAndResponseOverview;
