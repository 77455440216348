import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Spinner, Table } from "@skyportal/ui-kit";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { RequestStatus } from "types/common.types";
import {
  detectAndResponsePageActions,
  detectAndResponsePageSelectors,
  detectAndResponsePageThunks,
} from "store/detectAndResponsePage";
import { useThunkDispatch } from "store";
import { contractSelectors } from "store/contracts";
import { IncidentModel } from "types/detectAndResponseApi.types";
import { incidentStatusColorChartMap } from "components/DetectAndResponseOverview/charts/IncidentStatusChart/utils";
import { incidentSeverityColorChartMap } from "components/DetectAndResponseOverview/charts/IncidentSeverityChart/utils";
import CardFallback from "containers/CardFallback";
import { formatDateAndTime } from "utils/time";

import styles from "../../styles.module.css";
import { emptyAllIncidentsFilters } from "store/detectAndResponsePage/detectAndResponsePage.reducer";

const LatestIncidentsTable = () => {
  const { t } = useTranslation("detectAndResponsePage");
  const dispatch = useDispatch();
  const { list, requestStatus } = useSelector(detectAndResponsePageSelectors.getTop5Incidents);

  const handleViewAllBtnClick = useCallback(() => {
    dispatch(detectAndResponsePageActions.setAllIncidentsFiltersAC(emptyAllIncidentsFilters));
  }, [dispatch]);

  const thunkDispatch = useThunkDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContract)?.name as string;
  const reloadData = useCallback(
    () => thunkDispatch(detectAndResponsePageThunks.getTop5IncidentsInfo(selectedContractId)),
    [selectedContractId, thunkDispatch]
  );

  const columnsConfig = useMemo(
    () => [
      {
        key: "status",
        dataIndex: "status",
        title: t("latestIncidentsTableColumns.status"),
        sortDisabled: true,
        width: "15%",
        render: (status: IncidentModel["status"]) => (
          <div className={styles.incidentTag} style={{ backgroundColor: incidentStatusColorChartMap[status] }}>
            {status}
          </div>
        ),
      },
      {
        key: "severity",
        dataIndex: "severity",
        title: t("latestIncidentsTableColumns.severity"),
        sortDisabled: true,
        width: "15%",
        render: (severity: IncidentModel["severity"]) => (
          <div className={styles.incidentTag} style={{ backgroundColor: incidentSeverityColorChartMap[severity] }}>
            {severity}
          </div>
        ),
      },
      {
        key: "id",
        dataIndex: "id",
        title: t("latestIncidentsTableColumns.id"),
        sortDisabled: true,
        width: "15%",
      },
      {
        key: "subject",
        dataIndex: "subject",
        title: t("latestIncidentsTableColumns.subject"),
        sortDisabled: true,
        width: "25%",
      },
      {
        key: "created",
        dataIndex: "created",
        title: t("latestIncidentsTableColumns.created"),
        sortDisabled: true,
        width: "15%",
        render: (created: IncidentModel["created"]) => formatDateAndTime(created),
      },
      {
        key: "_",
        dataIndex: "_",
        title: "",
        sortDisabled: true,
        width: "15%",
        render: (_: undefined, incident: IncidentModel) => (
          <Link to={`/security/detection-and-response/incidents/${incident.id}`} className={styles.latestIncidentBtn}>
            <Button size="small">{t("latestIncidentsTableColumns.viewDetailsBtn")}</Button>
          </Link>
        ),
      },
    ],
    [t]
  );

  return (
    <Card title={t("latestIncidentsTableTitle")} className={styles.card} data-testid="latestIncidentsTable">
      <div className={styles.tableCardContent}>
        {requestStatus === RequestStatus.PENDING && <Spinner show />}
        {requestStatus === RequestStatus.SUCCESS && (
          <>
            <div className={styles.detectResponseTableBlock}>
              <Table dataSource={list} rowKey="latestIncident" columns={columnsConfig} />
            </div>
            <div className={styles.latestIncidentsFooter}>
              <Link to={"/security/detection-and-response/incidents"}>
                <Button rightIcon="arrow-right" onClick={handleViewAllBtnClick}>
                  {t("latestIncidentsViewAllBtn")}
                </Button>
              </Link>
            </div>
          </>
        )}
        {requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadData} />}
      </div>
    </Card>
  );
};

export default LatestIncidentsTable;
