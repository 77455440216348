import i18n from "i18next";

export interface BreadcrumbsItem {
  label: string;
  path: string;
  dustinIcon?: string;
  isDisabled?: boolean;
}

export interface BreadcrumbsConfigItem {
  label: string;
  crumbs?: BreadcrumbsItem[];
  dustinIcon?: string;
  match: { path: string; exact?: boolean };
}

export const getBreadcrumbsConfig = () => {
  const StartPage: BreadcrumbsItem = {
    label: i18n.t("breadcrumbs:Start"),
    path: "/start",
  };

  const Security: BreadcrumbsItem = {
    label: i18n.t("breadcrumbs:Security"),
    path: "/security/security-ready",
    isDisabled: true,
  };

  const breadcrumbsConfig: BreadcrumbsConfigItem[] = [
    {
      label: Security.label,
      crumbs: [StartPage],
      match: {
        path: Security.path,
        exact: true,
      },
    },
    {
      label: i18n.t("breadcrumbs:Proactive Security"),
      crumbs: [StartPage, Security],
      match: {
        path: "/security/proactive-security",
      },
    },
    {
      label: i18n.t("breadcrumbs:Detection and Response"),
      crumbs: [StartPage, Security],
      match: {
        path: "/security/detection-and-response",
      },
    },
  ];

  return breadcrumbsConfig;
};
