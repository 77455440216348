import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { Icon, Spinner } from "@skyportal/ui-kit";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { InfoBoxesCardData, InfoBoxesCardsConfig, SECURE_SCORE_VARIANT } from "./constants";
import { InfoBoxesCardConfigType, InfoBoxesCardType, InfoBoxesCardVariantDataType } from "./types";
import { RequestStatus } from "types/common.types";
import { composeProgressBarOptions } from "./utils";
import CardFallback from "containers/CardFallback";
import { contractSelectors } from "store/contracts";
import { useThunkDispatch } from "store";

import styles from "./styles.module.css";

interface InfoBoxesCardProps {
  variant: InfoBoxesCardType;
}

const InfoBoxesCard: React.FC<InfoBoxesCardProps> = ({ variant }) => {
  const { t } = useTranslation("securityPage");
  const cardData = useSelector(InfoBoxesCardsConfig[variant].selector);
  const cardThunk = InfoBoxesCardsConfig[variant].thunk;
  const cardDataNames: InfoBoxesCardVariantDataType = InfoBoxesCardData[variant];
  const cardConfig: InfoBoxesCardConfigType = InfoBoxesCardsConfig[variant];
  const wrapperClasses = cn(styles.root, styles[cardConfig.style]);

  const thunkDispatch = useThunkDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContract)?.name as string;
  const reloadData = useCallback(
    () => thunkDispatch(cardThunk(selectedContractId)),
    [cardThunk, selectedContractId, thunkDispatch]
  );

  const chartOptions = useMemo(() => {
    if (variant === SECURE_SCORE_VARIANT) return composeProgressBarOptions(cardData);
  }, [cardData, variant]);

  return (
    <div className={wrapperClasses}>
      {cardData.requestStatus === RequestStatus.PENDING && (
        <Spinner data-testid="infoBoxSpinner" show={cardData?.requestStatus !== RequestStatus.SUCCESS} />
      )}
      {cardData.requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadData} />}
      <div className={styles.infoBox}>
        <Icon type="fontawesome" className={styles.bgIcon} icon={cardConfig.icon} />
        <div className={styles.label}>{t(cardConfig.labelPhrase, { number: cardData[cardDataNames[0]] })}</div>
        <div className={styles.subLabel}>
          {t(cardConfig.subLabelPhrase, {
            firstNum: cardData[cardDataNames[1]],
            secondNum: cardData[cardDataNames[2]],
          })}
        </div>
        {variant === SECURE_SCORE_VARIANT && (
          <div className={styles.progressBarBlock}>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
            {/* Target Percentage line
             <div className={styles.targetLine} style={{ left: `calc(${cardData.targetPercentage}% - 2px)` }} /> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default InfoBoxesCard;
